
// This function takes a string representing a byte array and creates 
// a file to download with the filename provided
export function byteArrayDownloadUtility(
    input: string,
    outputFilename: string
) {
    var binaryString = window.atob(input);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }

    var blob = new Blob([bytes], {
        type: "application/octet-stream",
    });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = outputFilename;
    link.click();
}
