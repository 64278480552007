import { pluck } from "rxjs/operators";
import { bindable, autoinject } from "aurelia-framework";
import { NavModel, Router } from "aurelia-router";
import { StateManager } from "services/state-manager";
import { connectTo } from "aurelia-store";
import { IOrganization, IProject, IUser } from "models/interfaces";

@autoinject
@connectTo({
    selector: {
        organization: (store) => store.state.pipe(pluck("organization")),
    },
})
export class LeftSidebarElement {
    @bindable router: Router;
    @bindable showLoadScreen: Function;
    @bindable hideLoadScreen: Function;

    _stateManager: StateManager;
    organization: IOrganization;

    constructor(stateManager: StateManager) {
        this._stateManager = stateManager;
    }

    organizationChanged(
        organizationNew: IOrganization,
        organizationOld: IOrganization
    ) {
        this.organization = organizationNew;
    }

    routeSelected(routerItem: NavModel) {
        if (routerItem.title === "Services") {
            routerItem.isActive = !routerItem.isActive;

            return;
        }
        this.deactivateAllRoutes();
        this.navigateToView(routerItem);
    }

    navigateToSubView(page) {
        this.deactivateNonServicesRoutes();

        this.router.navigate(page.href, { replace: true });
        page.isActive = true;
    }

    navigateToView(page) {
        page.isActive = true;
        this.router.navigate(page.href, { replace: true });
    }
    deactivateAllRoutes() {
        this.router.navigation.forEach((navItem) => {
            navItem.isActive = false;
            if (navItem.settings.subPages.length > 0) {
                navItem.settings.subPages.forEach((subPage) => {
                    subPage.isActive = false;
                });
            }
        });
    }

    deactivateNonServicesRoutes() {
        this.router.navigation.forEach((navItem) => {
            if (navItem.title !== "Services") {
                navItem.isActive = false;
            }
            if (navItem.title === "Services") {
                navItem.settings.subPages.forEach((subPage) => {
                    subPage.isActive = false;
                });
            }
        });
    }
}
