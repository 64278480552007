import { IAsset, IAssetGroup, IPolyline } from "./../models/interfaces";
import { pluck } from "rxjs/operators";
import {
    bindable,
    bindingMode,
    autoinject,
    TaskQueue,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { StateManager } from "services/state-manager";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

@autoinject
@connectTo({
    selector: {
        assets: (store) => store.state.pipe(pluck("assets")),
        asset: (store) => store.state.pipe(pluck("asset")),
        polylines: (store) => store.state.pipe(pluck("polylines")),
        polyline: (store) => store.state.pipe(pluck("polyline")),
        isTerrainVisible: (store) =>
            store.state.pipe(pluck("isTerrainVisible")),
        isMapVisible: (store) => store.state.pipe(pluck("isMapVisible")),
        rightSidebarOpen: (store) =>
            store.state.pipe(pluck("rightSidebarOpen")),
    },
})
export class RightSidebarElement {
    _stateManager: StateManager;
    _taskQueue: TaskQueue;

    clonedAssets: IAsset[];
    vehicleAssets: IAsset[];
    structureAssets: IAsset[];
    jumperAssets: IAsset[];
    otherAssets: IAsset[];

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedAssetId: string;

    clonedPolylines: IPolyline[];

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selectedPolylineId: string;

    isAssetsVisible: boolean = true;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    isVehiclesListVisible: boolean = true;
    isStructuresVisible: boolean = true;
    isJumpersVisible: boolean = true;
    isOtherAssetsVisible: boolean = true;

    isPolylinesVisible: boolean = false;

    isEnvironmentsVisible: boolean = false;
    isTerrainVisible: boolean = false;
    isMapVisible: boolean = false;

    tooltips: any;

    isRightSidebarOpen: boolean = true;

    constructor(stateManager: StateManager, taskQueue: TaskQueue) {
        this._stateManager = stateManager;
        this._taskQueue = taskQueue;
    }

    addToolTips() {
        // 	this.tooltips = tippy("[data-tippy-content]", {
        // 		placement: "left",
        // 		theme: "light",
        // 		zIndex: 100001,
        // 		delay: 1000, // ms
        // 	});
    }

    removeToolTips() {
        this.tooltips.destroyAll();
    }

    created() {
        setTimeout(this.addToolTips, 5000);
    }

    assetsChanged(newAssets: IAsset[], oldAssets: IAsset[]) {
        if (newAssets == null) return;
        if (newAssets == oldAssets) return;

        newAssets.forEach((asset) => {
            asset.isVisible = true;
        });

        this.clonedAssets = structuredClone(newAssets).sort((a, b) =>
            a.name.localeCompare(b.name)
        );

        this.vehicleAssets = this.clonedAssets.filter(
            (asset) => asset.group == IAssetGroup.Vehicles
        );
        this.structureAssets = this.clonedAssets.filter(
            (asset) => asset.group == IAssetGroup.Structures
        );
        this.jumperAssets = this.clonedAssets.filter(
            (asset) => asset.group == IAssetGroup.Jumpers
        );
        this.otherAssets = this.clonedAssets.filter((asset) => {
            asset.group != IAssetGroup.Jumpers &&
                asset.group != IAssetGroup.Vehicles &&
                asset.group != IAssetGroup.Structures;
        });
        this.addToolTips();
    }
    assetChanged(assetNew: IAsset, assetOld: IAsset) {
        if (assetNew == null) return;

        if (assetNew == assetOld) return;

        this.selectedAssetId = assetNew.assetId;
    }
    polylinesChanged(newPolylines: IPolyline[], polylinesOld: IPolyline[]) {
        if (newPolylines == null) return;

        this.clonedPolylines = newPolylines
            .map((i) => ({ ...i }))
            .sort((a, b) => a.name.localeCompare(b.name));

        this.addToolTips();
    }
    polylineChanged(newPolyline: IPolyline, polylineOld: IPolyline) {
        if (newPolyline == null) return;

        if (newPolyline == polylineOld) return;

        this.selectedPolylineId = newPolyline.polylineId;
    }
    rightSidebarOpenChanged(newRightSidebarOpen: boolean) {
        this.isRightSidebarOpen = newRightSidebarOpen;
    }
    isTerrainVisibleChanged(newIsTerrainVisible: boolean) {
        this.isTerrainVisible = newIsTerrainVisible;
    }
    isMapVisibleChanged(newIsMapVisible: boolean) {
        this.isMapVisible = newIsMapVisible;
    }

    async selectAsset(asset: IAsset) {
        await this._stateManager.selectAssetAsync(Object.assign({}, asset));
    }
    async selectPolyline(polyline: IPolyline) {
        await this._stateManager.selectPolylineAsync(
            Object.assign({}, polyline)
        );

        console.log("Selected Polyline", polyline);
    }

    get allVehiclesVisible(): boolean {
        if (this.vehicleAssets == null) return false;

        return this.vehicleAssets.every((asset) => asset.isVisible);
    }
    get allStructuresVisible(): boolean {
        if (this.structureAssets == null) return false;

        return this.structureAssets.every((asset) => asset.isVisible);
    }
    get allJumpersVisible(): boolean {
        if (this.jumperAssets == null) return false;

        return this.jumperAssets.every((asset) => asset.isVisible);
    }
    get allOtherAssetsVisible(): boolean {
        if (this.otherAssets == null) return false;

        return this.otherAssets.every((asset) => asset.isVisible);
    }
    get allPolylinesVisible(): boolean {
        if (this.clonedPolylines == null) return false;

        return this.clonedPolylines.every((polyline) => polyline.isVisible);
    }

    toggleAssetsVisibility() {
        this.isAssetsVisible = !this.isAssetsVisible;
    }
    togglePolylinesVisibility() {
        this.isPolylinesVisible = !this.isPolylinesVisible;
    }
    toggleVehiclesVisibility() {
        this.isVehiclesListVisible = !this.isVehiclesListVisible;
        if (this.isVehiclesListVisible == true) {
            setTimeout(this.addToolTips, 500);
        }
    }
    toggleStructuresVisibility() {
        this.isStructuresVisible = !this.isStructuresVisible;
        if (this.isStructuresVisible == true) {
            setTimeout(this.addToolTips, 500);
        }
    }
    toggleJumpersVisibility() {
        this.isJumpersVisible = !this.isJumpersVisible;
        if (this.isJumpersVisible == true) {
            setTimeout(this.addToolTips, 500);
        }
    }
    toggleOtherAssetsVisibility() {
        this.isOtherAssetsVisible = !this.isOtherAssetsVisible;
        if (this.isOtherAssetsVisible == true) {
            setTimeout(this.addToolTips, 500);
        }
    }

    toggleEnvironmentsVisibility() {
        this.isEnvironmentsVisible = !this.isEnvironmentsVisible;
        if (this.isEnvironmentsVisible == true) {
            setTimeout(this.addToolTips, 500);
        }
    }

    toggleAssetVisibility(asset: IAsset) {
        asset.isVisible = !asset.isVisible;
        this._stateManager.setAssetVisibility(asset);
    }
    togglePolylineVisibility(polyline: IPolyline) {
        polyline.isVisible = !polyline.isVisible;
        this._stateManager.setPolylineVisibility(polyline);
    }

    toggleAllVehiclesVisibility() {
        var allVisible = !this.allVehiclesVisible;

        this.vehicleAssets.forEach((asset) => {
            asset.isVisible = allVisible;
            this._stateManager.setAssetVisibility(asset);
        });
    }
    toggleAllStructuresVisibility() {
        var allVisible = !this.allStructuresVisible;

        this.structureAssets.forEach((asset) => {
            asset.isVisible = allVisible;
            this._stateManager.setAssetVisibility(asset);
        });
    }
    toggleAllJumpersVisibility() {
        var allVisible = !this.allJumpersVisible;

        this.jumperAssets.forEach((asset) => {
            asset.isVisible = allVisible;
            this._stateManager.setAssetVisibility(asset);
        });
    }
    toggleAllOtherAssetsVisibility() {
        var allVisible = !this.allOtherAssetsVisible;

        this.otherAssets.forEach((asset) => {
            asset.isVisible = allVisible;
            this._stateManager.setAssetVisibility(asset);
        });
    }
    toggleAllPolylinesVisibility() {
        var allVisible = !this.allPolylinesVisible;

        this.clonedPolylines.forEach((polyline) => {
            polyline.isVisible = allVisible;
            this._stateManager.setPolylineVisibility(polyline);
        });
    }
    setTerrainVisibility(value: boolean) {
        this._stateManager.setTerrainVisibility(value);
    }
    setMapVisibility(value: boolean) {
        this._stateManager.setMapVisibility(value);
    }
}
