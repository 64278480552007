import { IFleet, ITerrain, IUser } from "models/interfaces";
import { State } from "./state";
import {
    IAsset,
    IOrganization,
    IProject,
    ISurveyLine,
    IVideo,
    IWaypoint,
    IPolyline,
} from "../models/interfaces";

/*********************/
/*** Organizations ***/
/*********************/

export const updateOrganizations = (
    state: State,
    organizations: Array<IOrganization>
) => {
    const newState = Object.assign({}, state);
    newState.organizations = organizations;
    if (
        state &&
        state.project == null &&
        organizations.length > 0 &&
        organizations[0].projects.length > 0
    )
        newState.project = organizations[0].projects[0];
    return newState;
};

export const updateOrganization = (
    state: State,
    organization: IOrganization
) => {
    const newState = Object.assign({}, state);
    newState.organization = organization;
    return newState;
};

/*********************/

/****************/
/*** Projects ***/
/****************/
export const updateProjects = (state: State, projects: Array<IProject>) => {
    const newState = Object.assign({}, state);
    newState.projects = projects;
    if (state && state.project == null && projects.length > 0)
        newState.project = projects[0];
    return newState;
};

export const updateProject = (state: State, project: IProject) => {
    const newState = Object.assign({}, state);
    newState.project = project;
    return newState;
};
/****************/

/**************/
/*** Videos ***/
/**************/
export const updateVideos = (state: State, videos: Array<IVideo>) => {
    const newState = Object.assign({}, state);
    newState.videos = videos;
    return newState;
};

export const updateVideo = (state: State, video: IVideo) => {
    const newState = Object.assign({}, state);
    newState.video = video;
    return newState;
};
/**************/

export const updateBroadcasts = (state: State, broadcasts: Array<IVideo>) => {
    const newState = Object.assign({}, state);
    newState.broadcasts = broadcasts;
    return newState;
};

/**************/

/*****************/
/*** Waypoints ***/
/*****************/
export const updateWaypoints = (state: State, waypoints: Array<IWaypoint>) => {
    const newState = Object.assign({}, state);
    newState.waypoints = waypoints;
    return newState;
};

export const updateWaypoint = (state: State, waypoint: IWaypoint) => {
    const newState = Object.assign({}, state);
    newState.waypoint = waypoint;
    return newState;
};
/**************/

/********************/
/*** Survey Lines ***/
/********************/
export const updateSurveyLines = (
    state: State,
    surveyLines: Array<ISurveyLine>
) => {
    const newState = Object.assign({}, state);
    newState.surveyLines = surveyLines;
    return newState;
};

export const addSurveyLine = (state: State, surveyLine: ISurveyLine) => {
    const newState = Object.assign({}, state);
    newState.surveyLines.push(surveyLine);
    return newState;
};

export const updateSurveyLine = (state: State, surveyLine: ISurveyLine) => {
    const newState = Object.assign({}, state);
    newState.surveyLine = surveyLine;

    let surveyLineIndex = newState.surveyLines.findIndex((surveyLine) => {
        return surveyLine.surveyLineId == surveyLine.surveyLineId;
    });
    newState.surveyLines[surveyLineIndex] = surveyLine;

    return newState;
};

export const deleteSurveyLine = (state: State, surveyLine: ISurveyLine) => {
    const newState = Object.assign({}, state);
    newState.surveyLines = newState.surveyLines.filter((surveyLine) => {
        return surveyLine.surveyLineId != surveyLine.surveyLineId;
    });
    return newState;
};
/********************/

/*****************/
/**** Vessel ****/
/*****************/
export const updateAssets = (state: State, assets: Array<IAsset>) => {
    const newState = Object.assign({}, state);
    newState.assets = assets;
    return newState;
};

export const updateAsset = (state: State, asset: IAsset) => {
    const newState = Object.assign({}, state);
    newState.asset = asset;
    return newState;
};
/**************/

/*****************/
/**** Fleets ****/
/*****************/
export const updateFleets = (state: State, fleets: Array<IFleet>) => {
    const newState = Object.assign({}, state);
    newState.fleets = fleets;
    return newState;
};

export const updateFleet = (state: State, fleet: IFleet) => {
    const newState = Object.assign({}, state);
    newState.fleet = fleet;
    return newState;
};
/**************/

/****************/
/*** TERRAINS ***/
/****************/

export const updateTerrains = (state: State, terrains: Array<ITerrain>) => {
    const newState = Object.assign({}, state);
    newState.terrains = terrains;
    return newState;
};
/****************/

/*****************/
/*** POLYLINES ***/
/*****************/

export const updatePolylines = (state: State, polylines: Array<IPolyline>) => {
    const newState = Object.assign({}, state);
    newState.polylines = polylines;
    return newState;
};
export const updatePolyline = (state: State, polyline: IPolyline) => {
    const newState = Object.assign({}, state);
    newState.polyline = polyline;
    return newState;
};
/*****************/

/*****************/
/*** POLYLINES ***/
/*****************/

export const updateAisData = (state: State, aisData: Array<any>) => {
    const newState = Object.assign({}, state);
    newState.aisData = aisData;
    return newState;
};

/*****************/

export const updateUserAssets = (state: State, userAssets: any) => {
    const newState = Object.assign({}, state);
    newState.userAssets = userAssets;
    return newState;
};

export const updateUsers = (state: State, users: IUser[]) => {
    const newState = Object.assign([], state);

    newState.users = users;

    return newState;
};

export const updateUser = (state: State, user: any) => {
    const newState = Object.assign({}, state);

    newState.user = user;

    return newState;
};

export const updateRightSidebar = (
    state: State,
    rightSidebarValue: boolean
) => {
    const newState = Object.assign({}, state);

    newState.rightSidebarOpen = rightSidebarValue;

    return newState;
};

export const setTerrainVisibility = (state: State, visibility: boolean) => {
    const newState = Object.assign({}, state);

    newState.isTerrainVisible = visibility;

    return newState;
};

export const setMapVisibility = (state: State, visibility: boolean) => {
    const newState = Object.assign({}, state);

    newState.isMapVisible = visibility;

    return newState;
};

export const setDarkMode = (state: State, boolValue: any) => {
    const newState = Object.assign({}, state);

    newState.darkUi = boolValue;

    return newState;
};
export const setTheaterMode = (state: State, boolValue: any) => {
    const newState = Object.assign({}, state);

    newState.theaterMode = boolValue;

    return newState;
};

export const clearState = (state: State) => {
    const newState = Object.assign({}, state);

    newState.organization = null;
    newState.organizations = null;
    newState.project = null;
    newState.projects = null;
    newState.video = null;
    newState.videos = null;
    newState.waypoint = null;
    newState.waypoints = null;
    newState.surveyLine = null;
    newState.surveyLines = null;
    newState.asset = null;
    newState.assets = null;
    newState.fleet = null;
    newState.fleets = null;
    newState.terrains = null;
    newState.polylines = null;
    newState.polyline = null;
    newState.aisData = null;
    newState.userAssets = null;
    newState.user = null;
    newState.rightSidebarOpen = false;
    newState.isTerrainVisible = true;
    newState.isMapVisible = true;

    return newState;
};
