import {
    IFleet,
    IPolyline,
    ISurveyLine,
    IUser,
    IUserVideoState,
} from "./../models/interfaces";
import { autoinject } from "aurelia-framework";
import { IWaypoint } from "models/interfaces";
import { AuthenticationService } from "./authentication-service";
// import { AuthenticationService } from "./authentication-service";

enum controller {
    Organizations = "organizations",
    Projects = "projects",
    Videos = "videos",
    Dongle = "license/dongle",
    License = "license",
    LicenseTest = "license/test",
    LicenseContact = "license/contact",
    Keylok = "license/keylok",
    KeylokAuth = "license/keylok/auth",
    KeylokVersions = "license/keylok/versions",
    KeylokHealth = "license/keylok/health",
    KeylokMaintenance = "license/keylok/maintenance",
    KeylokOrganization = "license/keylok/organization",
    SoftwareKey = "license/softwareKey",
    SoftwareKeyVersions = "license/softwareKey/versions",
    SoftwareKeyCustomer = "license/softwareKey/customer",
    SoftwareKeyPopulate = "license/softwareKey/populate",
    Assets = "assets",
    Users = "users",
    Me = "users/me",
    Search = "search",
    Waypoints = "waypoints",
    SurveyLines = "surveylines",
    Terrains = "terrains",
    Polylines = "polylines",
    Ais = "ais",
    AntBroadcasts = "antbroadcasts",
    Vessels = "vessels",
    Fleets = "fleets",
    UserVideoState = "UserVideoStates",
    UserAssets = "UserAssets",
    OrganizationAssets = "OrganizationAssets",
}

@autoinject()
export class DataService {
    _authenticationService: AuthenticationService;

    constructor(authenticationService: AuthenticationService) {
        this._authenticationService = authenticationService;
    }

    /*********************/
    /*** Organizations ***/
    /*********************/
    async getOrganizationsFromServerAsync() {
        return await this.getListDataFromServerAsync(
            controller.Organizations,
            null
        );
    }
    async getOrganizationFromServerAsync(organizationId: string) {
        return await this.getSingleDataFromServerAsync(
            controller.Organizations,
            organizationId
        );
    }
    async postOrganizationAsync(organization: any) {
        return await this.postSingleDataAsync(
            controller.Organizations,
            organization
        );
    }
    async updateOrganizationAsync(organization: any) {
        return await this.updateSingleDataAsync(
            controller.Organizations,
            organization
        );
    }
    async deleteOrganizationFromServerAsync(organization: any) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Organizations,
            organization
        );
    }
    /*********************/

    /****************/
    /*** Projects ***/
    /****************/
    async getProjectsFromServerAsync(params) {
        return await this.getListDataFromServerAsync(
            controller.Projects,
            params
        );
    }
    async getProjectFromServerAsync(projectId: string) {
        return await this.getSingleDataFromServerAsync(
            controller.Projects,
            projectId
        );
    }
    async postProjectAsync(project: any, params) {
        return await this.postSingleDataAsync(
            controller.Projects,
            project,
            params
        );
    }
    async updateProjectAsync(project: any) {
        return await this.updateSingleDataAsync(controller.Projects, project);
    }
    async deleteProjectFromServerAsync(project: any, params?: any) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Projects,
            project,
            params
        );
    }
    /****************/

    /****************/
    /*** Dongles ***/
    /****************/
    async getDonglesFromServerAsync(parameters?: any) {
        return await this.getSingleDataFromServerAsync(
            controller.Dongle,
            parameters
        );
    }

    async getDongleFromServerAsync(serialNumber: string, parameters?: any) {
        return await this.getSingleDataFromServerAsync(
            controller.Dongle,
            serialNumber,
            parameters
        );
    }

    async postDonglesToServerAsync(serialNumbers: number[], parameters?: any) {
        return await this.postSingleDataAsync(
            controller.Dongle,
            serialNumbers,
            parameters
        );
    }

    /****************/
    /*** Licenses ***/
    /****************/
    async getLicensesFromServerAsync(organizationId: string, parameters?: any) {
        return await this.getSingleDataFromServerAsync(
            controller.License,
            organizationId,
            parameters
        );
    }

    async postLicensesToServerAsync(licenseFile: any, parameters?: any) {
        return await this.postSingleDataAsync(
            controller.License,
            licenseFile,
            parameters
        );
    }

    async updateLicenseAsync(license: any, params?: any) {
        return await this.updateSingleDataAsync(
            controller.License,
            license,
            params
        );
    }

    async getLicenseContactsFromServerAsync(organizationId: string, parameters?: any) {
        return await this.getSingleDataFromServerAsync(
            controller.LicenseContact,
            organizationId,
            parameters
        );
    }

    async postLicenseContactToServerAsync(contact: any, parameters?: any) {
        return await this.postSingleDataAsync(
            controller.LicenseContact,
            contact,
            parameters
        );
    }

    async updateLicenseContactAsync(contact: any, params?: any) {
        return await this.updateSingleDataAsync(
            controller.LicenseContact,
            contact,
            params
        );
    }

    async deleteLicenseContactAsync(contactId: string, params?: any) {
        return await this.deleteSingleDataFromServerAsync(
            controller.LicenseContact,
            contactId,
            params
        );
    }

    async test(params?: any) {
        return await this.getSingleDataFromServerAsync(
            controller.LicenseTest,
            params
        );
    }


    /***********************/
    /*** Keylok Licenses ***/
    /***********************/
    async postNewKeylokLicenseToServerAsync(license: any, params?: any) {
        return await this.postSingleDataAsync(
            controller.Keylok,
            license,
            params
        );
    }

    async getKeylokLicenseFromServerAsync(licenseId: string, parameters?: any) {
        return await this.getSingleDataFromServerAsync(
            controller.Keylok,
            licenseId,
            parameters
        );
    }

    async updateKeylokLicenseAsync(license: any, params?: any) {
        return await this.updateSingleDataAsync(
            controller.Keylok,
            license,
            params
        );
    }

    async updateKeylokLicenseMaintenanceAsync(licenseUpdate: any, params?: any) {
        return await this.updateSingleDataAsync(
            controller.KeylokMaintenance,
            licenseUpdate,
            params
        );
    }

    async updateKeylokOrganizationAsync(licenseUpdate: any, params?: any) {
        return await this.updateSingleDataAsync(
            controller.KeylokOrganization,
            licenseUpdate,
            params
        );
    }

    async deleteKeylokLicenseAsync(licenseId: string, params?: any) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Keylok,
            licenseId,
            params
        );
    }

    async getKeylokLicenseVersionsFromServerAsync(
        licenseId: string,
        parameters?: any
    ) {
        return await this.getSingleDataFromServerAsync(
            controller.KeylokVersions,
            licenseId,
            parameters
        );
    }

    async getKeylokAuthFromServerAsync(licenseId: string, parameters?: any) {
        return await this.getSingleDataFromServerAsync(
            controller.KeylokAuth,
            licenseId,
            parameters
        );
    }

    async getKeylokServerStatus(parameters?: any): Promise<boolean> {
        let fetchUrl = `/${controller.KeylokHealth}`;
        var status: boolean = false;

        await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        }).then((response) => {
            if (response.ok) {
                status = true;
            } else {
                console.log("No response from server");
                status = false;
            }
        });

        return status;
    }


    /****************************/
    /*** SoftwareKey Licenses ***/
    /****************************/
    async postNewSoftwareKeyLicenseToServerAsync(license: any, params?: any) {
        return await this.postSingleDataAsync(
            controller.SoftwareKey,
            license,
            params
        );
    }

    async getSoftwareKeyLicenseFromServerAsync(
        licenseId: string,
        parameters?: any
    ) {
        return await this.getSingleDataFromServerAsync(
            controller.SoftwareKey,
            licenseId,
            parameters
        );
    }

    async updateSoftwareKeyLicenseAsync(license: any, params?: any) {
        return await this.updateSingleDataAsync(
            controller.SoftwareKey,
            license,
            params
        );
    }

    async deleteSoftwareKeyLicenseAsync(licenseId: string, params?: any) {
        return await this.deleteSingleDataFromServerAsync(
            controller.SoftwareKey,
            licenseId,
            params
        );
    }

    async getSoftwareKeyLicenseVersionsFromServerAsync(
        licenseId: string,
        parameters?: any
    ) {
        return await this.getSingleDataFromServerAsync(
            controller.SoftwareKeyVersions,
            licenseId,
            parameters
        );
    }

    async postNewSoftwareKeyCustomerToServerAsync(
        companyName: any,
        params?: any
    ) {
        return await this.postSingleDataAsync(
            controller.SoftwareKeyCustomer,
            companyName,
            params
        );
    }

    async getSoftwareKeyCustomerIdFromServerAsync(
        companyId: string,
        parameters?: any
    ) {
        return await this.getSingleDataFromServerAsync(
            controller.SoftwareKeyCustomer,
            companyId,
            parameters
        );
    }

    async postSoftwareKeyLicensesToServerAsync(licenseFile: any, parameters?: any) {
        return await this.postSingleDataAsync(
            controller.SoftwareKeyPopulate,
            licenseFile,
            parameters
        );
    }

    /**************/
    /*** Videos ***/
    /**************/
    async getVideosFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            controller.Videos,
            parameters
        );
    }
    async getVideoFromServerAsync(dataId, params) {
        return await this.getSingleDataFromServerAsync(
            controller.Videos,
            dataId,
            params
        );
    }
    async postVideoAsync(video: any) {
        return await this.postSingleDataAsync(controller.AntBroadcasts, video);
    }
    async updateVideoAsync(video: any) {
        return await this.updateSingleDataAsync(controller.Videos, video);
    }
    async deleteVideoFromServerAsync(video: any) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Videos,
            video
        );
    }
    /**************/

    /******************/
    /*** Broadcasts ***/
    /******************/

    async getBroadcastsFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            controller.AntBroadcasts,
            parameters
        );
    }
    async deleteBroadcastFromServerAsync(broadcast: any) {
        return await this.deleteSingleDataFromServerAsync(
            controller.AntBroadcasts,
            broadcast
        );
    }

    /******************/

    /*****************/
    /*** Waypoints ***/
    /*****************/
    async getWaypointsFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            controller.Waypoints,
            parameters
        );
    }
    async getWaypointFromServerAsync(waypointId: string) {
        return await this.getSingleDataFromServerAsync(
            controller.Waypoints,
            waypointId
        );
    }
    async postWaypointAsync(waypoint: IWaypoint) {
        return await this.postSingleDataAsync(controller.Waypoints, waypoint);
    }
    async updateWaypointAsync(waypoint: IWaypoint) {
        return await this.updateSingleDataAsync(controller.Waypoints, waypoint);
    }
    async deleteWaypointFromServerAsync(waypoint: IWaypoint) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Waypoints,
            waypoint
        );
    }
    /**************/

    /********************/
    /*** Survey Lines ***/
    /********************/
    async getSurveyLinesFromServerAsync() {
        return await this.getListDataFromServerAsync(
            controller.SurveyLines,
            {}
        );
    }
    async getSurveyLineFromServerAsync(surveyLineId: string) {
        return await this.getSingleDataFromServerAsync(
            controller.SurveyLines,
            surveyLineId
        );
    }
    async postSurveyLineAsync(surveyLine: ISurveyLine) {
        return await this.postSingleDataAsync(
            controller.SurveyLines,
            surveyLine
        );
    }
    async updateSurveyLineAsync(surveyLine: ISurveyLine) {
        return await this.updateSingleDataAsync(
            controller.SurveyLines,
            surveyLine
        );
    }
    async deleteSurveyLineFromServerAsync(surveyLine: ISurveyLine) {
        return await this.deleteSingleDataFromServerAsync(
            controller.SurveyLines,
            surveyLine
        );
    }
    /**************/

    /**************/
    /*** Vessel ***/
    /**************/
    async getAssetsFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            controller.Vessels,
            parameters
        );
    }
    async getAssetFromServerAsync(dataId) {
        return await this.getSingleDataFromServerAsync(
            controller.Vessels,
            dataId
        );
    }
    async postAssetAsync(vessel: any, params?) {
        return await this.postSingleDataAsync(
            controller.Vessels,
            vessel,
            params
        );
    }
    async updateAssetAsync(vessel: any, params?) {
        return await this.updateSingleDataAsync(
            controller.Vessels,
            vessel,
            params
        );
    }
    async deleteAssetFromServerAsync(vessel: any, params?) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Vessels,
            vessel,
            params
        );
    }
    /**************/

    /**************/
    /*** Fleets ***/
    /**************/
    async getFleetsFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            controller.Fleets,
            parameters
        );
    }
    async getFleetFromServerAsync(dataId) {
        return await this.getSingleDataFromServerAsync(
            controller.Fleets,
            dataId
        );
    }
    async postFleetAsync(fleet: IFleet, params?) {
        return await this.postSingleDataAsync(controller.Fleets, fleet, params);
    }
    async updateFleetAsync(fleet: any, params?) {
        return await this.updateSingleDataAsync(
            controller.Fleets,
            fleet,
            params
        );
    }
    async deleteFleetFromServerAsync(fleet: any, params?) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Fleets,
            fleet,
            params
        );
    }
    /**************/

    /****************/
    /*** Terrains ***/
    /****************/

    async getTerrainsFromServerAsync() {
        return await this.getListDataFromServerAsync(controller.Terrains, {});
    }

    /**************/

    async getPolylinesFromServerAsync() {
        var unsortedPolylines = await this.getListDataFromServerAsync(
            controller.Polylines,
            {}
        );
        const sortedPolylines = unsortedPolylines.sort((a, b) => {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        sortedPolylines.forEach((sortedPolylines: IPolyline) => {
            sortedPolylines.isVisible = true;
        });

        return sortedPolylines;
    }

    async getAisDataFromServerAsync() {
        return await this.getListDataFromServerAsync(controller.Ais, {});
    }

    //#region User Data Service Methods
    async getUsersFromServerAsync(params) {
        return await this.getListDataFromServerAsync(controller.Users, params);
    }
    async updateUserAsync(user: IUser, params?) {
        return await this.updateSingleDataAsync(controller.Users, user, params);
    }
    async addUserAsync(user: IUser, params?) {
        return await this.postSingleDataAsync(controller.Users, user);
    }
    async deleteUserFromServerAsync(user: any, params) {
        return await this.deleteSingleDataFromServerAsync(
            controller.Users,
            user,
            params
        );
    }
    async getUserFromServerAsync() {
        return await this.getSingleDataFromServerAsync(controller.Me, null);
    }
    async updateUserVideoStateAsync(userVideoState: IUserVideoState) {
        return await this.updateSingleDataAsync(
            controller.UserVideoState,
            userVideoState
        );
    }

    async getUserAssetsAsync(params?) {
        return await this.getListDataFromServerAsync(
            controller.UserAssets,
            params
        );
    }
    //#endregion

    async getOrganizationAssets(organizationId: string) {
        return await this.getSingleDataFromServerAsync(
            controller.OrganizationAssets,
            organizationId
        );
    }

    //#region Search Results Methods
    async getSearchResultsFromServerAsync(requestParameters: {
        searchKey: string;
    }) {
        return await this.getListDataFromServerAsync(
            controller.Search,
            requestParameters
        );
    }

    async getListDataFromServerAsync(
        controller: controller,
        requestParameters: any
    ) {
        let fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });

        const jsonUnpackedData = await returnedData.json();

        return jsonUnpackedData;
    }

    async getSingleDataFromServerAsync(
        controller: controller,
        dataId: any,
        requestParameters?: any
    ) {
        let fetchUrl = "";

        if (dataId != null) fetchUrl = `/${controller}/${dataId}`;
        else fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });

        const jsonUnpackedData = await returnedData.json();

        return jsonUnpackedData;
    }

    async getPdfDataFromServerAsync(requestParameters: {
        propertyId: number;
        clientId: number;
    }) {
        let fetchUrl = `/pdf`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
            },
        });
    }

    async postSingleDataAsync(
        controller: controller,
        entity: any,
        requestParameters?: any
    ) {
        let fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(entity),
        });

        try {
            const jsonUnpackedData = await returnedData.json();
            return jsonUnpackedData;
        } catch {
            return;
        }  
    }

    async updateSingleDataAsync(
        controller: controller,
        entity: any,
        requestParameters?: any
    ) {
        let fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(entity),
        });

        try {
            const jsonUnpackedData = await returnedData.json();
            return jsonUnpackedData;
        } catch {
            return;
        }  
    }

    async updateListDataAsync(
        controller: controller,
        entity: any,
        requestParameters: any
    ) {
        let fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(entity),
        });

        try {
            const jsonUnpackedData = await returnedData.json();
            return jsonUnpackedData;
        } catch {
            return;
        }    
    }

    async deleteSingleDataFromServerAsync(
        controller: controller,
        entity: any,
        requestParameters?: any
    ) {
        let fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(entity),
        });
    }

    async getTemplateDataFromServerAsync(controller: controller) {
        let fetchUrl = `/${controller}`;

        const returnedData = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });

        const jsonUnpackedData = await returnedData.json();

        return jsonUnpackedData;
    }

    private constructQueryString(parameters) {
        var queryString = "";

        for (var prop in parameters) {
            if (parameters.hasOwnProperty(prop) && parameters[prop] != null) {
                queryString +=
                    queryString == ""
                        ? `?${prop}=${parameters[prop]}`
                        : `&${prop}=${parameters[prop]}`;
            }
        }

        return queryString;
    }
}
