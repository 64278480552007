import { IAisData, IFleet, IPolyline, IUser } from "models/interfaces";
import { ISurveyLine, ITerrain, IWaypoint } from "./../models/interfaces";
import { IAsset, IOrganization, IProject, IVideo } from "../models/interfaces";

export interface State {
    organizations: Array<IOrganization>;
    organization: IOrganization;

    projects: Array<IProject>;
    project: IProject;

    terrains: ITerrain[];

    polylines: IPolyline[];
    polyline: IPolyline;

    videos: Array<IVideo>;
    video: IVideo;
    fleets: Array<IFleet>;
    fleet: IFleet;

    broadcasts: Array<IVideo>;

    assets: Array<IAsset>;
    asset: IAsset;

    waypoint: IWaypoint;
    waypoints: Array<IWaypoint>;

    surveyLine: ISurveyLine;
    surveyLines: Array<ISurveyLine>;

    aisData: Array<IAisData>;

    users: Array<IUser>;
    user: IUser;
    userAssets: any;

    rightSidebarOpen: boolean;

    isTerrainVisible: boolean;
    isMapVisible: boolean;
    darkUi: boolean;
    theaterMode: boolean;
}

export const initialState: State = {
    organizations: [],
    organization: null,
    projects: [],
    project: null,

    terrains: [],

    polylines: [],
    polyline: null,

    videos: [],
    video: null,

    fleets: [],
    fleet: null,

    broadcasts: [],

    assets: [],
    asset: null,

    waypoint: null,
    waypoints: [],

    surveyLine: null,
    surveyLines: [],

    aisData: [],

    users: [],
    user: null,
    userAssets: [],

    rightSidebarOpen: false,

    isTerrainVisible: true,
    isMapVisible: false,
    darkUi: true,
    theaterMode: false,
};
