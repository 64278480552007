import { pluck } from "rxjs/operators";
import { Router } from "aurelia-router";
import {
	bindable,
	autoinject,
	observable,
	bindingMode,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import { DialogService } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { IOrganization, IProject, IUser } from "models/interfaces";
import tippy from "tippy.js";

autoinject();
@connectTo({
	selector: {
		user: (store) => store.state.pipe(pluck("user")),
		organization: (store) => store.state.pipe(pluck("organization")),
		organizations: (store) => store.state.pipe(pluck("organizations")),
		projects: (store) => store.state.pipe(pluck("projects")),
		project: (store) => store.state.pipe(pluck("project")),
		darkUi: (store) => store.state.pipe(pluck("darkUi")),
		theaterMode: (store) => store.state.pipe(pluck("theaterMode")),
	},
})
export class TopbarElement {
	_stateManager: StateManager;
	_dialogService: DialogService;
	state: State;
	_authenticationService: AuthenticationService;
	@bindable router: Router;
	darkMode: boolean;
	@bindable({ defaultBindingMode: bindingMode.twoWay })
	leftSidebarOpen: boolean;
	theaterMode: boolean;
	tooltips: any;

	@observable()
	organization: IOrganization;
	organizations: IOrganization[];
	projects: IProject[];

	userMenuIsOpen: boolean = false;
	user: IUser;

	organizationsMenuIsOpen: boolean = false;
	projectsMenuIsOpen: boolean = false;

	styleObject: any;

	constructor(
		stateManager: StateManager,
		dialogService: DialogService,
		state: State,
		authenticationService: AuthenticationService
	) {
		this._stateManager = stateManager;
		this._dialogService = dialogService;
		this.state = state;
		this._authenticationService = authenticationService;
	}

	attached() {
		this.addToolTips();
	}

	darkModeChanged(newValue, oldValue) {
		this.darkMode = newValue;
	}
	theaterModeChanged(newValue, oldValue) {
		this.theaterMode = newValue;
	}

	userChanged(newUser: IUser, oldUser: IUser) {
		if (!newUser) {
			return;
		}

		if (newUser == oldUser) {
			return;
		}

		this.user = newUser;
	}
	organizationChanged(
		newOrganization: IOrganization,
		oldOrganization: IOrganization
	) {
		if (!newOrganization) {
			return;
		}

		if (newOrganization == oldOrganization) {
			return;
		}

		this.organization = newOrganization;
	}
	organizationsChanged(
		newOrganizations: IOrganization[],
		oldOrganizations: IOrganization[]
	) {
		if (newOrganizations == null || newOrganizations.length == 0) {
			return;
		}

		this.organizations = newOrganizations;
	}
	projectsChanged(newProjects: IProject[], oldprojects: IProject[]) {
		this.projects = newProjects;
	}

	logout() {
		this._stateManager.clearState();
		this._authenticationService.logout();
	}

	toggleMenu(menuName) {
		switch (menuName) {
			case "user":
				this.userMenuIsOpen = !this.userMenuIsOpen;
				break;
			case "organizations":
				{
					if (this.organizations.length < 2) return;
					this.organizationsMenuIsOpen =
						!this.organizationsMenuIsOpen;
				}
				break;
			case "projects":
				this.projectsMenuIsOpen = !this.projectsMenuIsOpen;
				break;
		}
	}

	toggleLeftSidebar() {
		//get the left siebar to expand.
		this.leftSidebarOpen = !this.leftSidebarOpen;
	}
	toggleTheaterMode() {
		this._stateManager.setTheaterMode(!this.theaterMode);
	}

	closeMenu(menuName) {
		switch (menuName) {
			case "user":
				this.userMenuIsOpen = false;
				break;
			case "organizations":
				this.organizationsMenuIsOpen = false;
				break;
			case "projects":
				this.projectsMenuIsOpen = false;
				break;
		}
	}

	navigateToPage(pageString) {
		this.router.navigate(pageString, { replace: true });
		this.userMenuIsOpen = false;
	}

	async selectProject(project: IProject) {
		await this._stateManager.selectProjectAsync(project);
	}

	setDarkMode(value) {
		this._stateManager.setDarkMode(value);
	}

	async selectOrganization(organization) {
		await this._stateManager.selectOrganization(organization);
		await this._stateManager.getAssetsAsync({
			organizationId: organization.organizationId,
		});
		await this._stateManager.getUserAssetsAsync({
			organizationId: organization.organizationId,
		});
		await this._stateManager.getFleetsAsync({
			organizationId: organization.organizationId,
		});
		await this._stateManager.getUsersAsync({
			organizationId: organization.organizationId,
		});
		await this._stateManager.getProjectsAsync({
			organizationId: organization.organizationId,
		});
		this.organizationsMenuIsOpen = false;
	}

	addToolTips() {
		this.tooltips = tippy("[data-tippy-content]", {
			placement: "top",
			theme: "light",
			zIndex: 100001,
			delay: 200, // ms
		});
	}
}
