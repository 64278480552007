import { IUser } from 'models/interfaces';
import { autoinject } from 'aurelia-framework';
import { connectTo } from 'aurelia-store';
import { pluck } from 'rxjs/operators';
import { DataService } from './data-service';

autoinject();
@connectTo({
	selector: {
		user: (store) => store.state.pipe(pluck("user"))
	}
})
export class UserRoleService {

	private _dataService: DataService;

	user: IUser;

	constructor(dataService: DataService) {
		this._dataService = dataService;

		this.fetchUser();
	}

	fetchUser() {
		this._dataService.getUserFromServerAsync().then((user) => {
			this.user = user;
		});
	}

	get isAdmin() {
		return this.user && this.user.isAdmin;
	}

	get is4DNavAdmin() {
		return this.user && this.user.isAdmin && this.user.organization.organizationId.toUpperCase() === "441D2B89-DA76-4BCC-A194-69BC14096F92";
	}
}