import { UserRoles } from "./../pages/user-roles";
import { IFleet, IPolyline, ISurveyLine, IUser } from "./../models/interfaces";
import { DataService } from "./data-service";
import {
	addSurveyLine,
	clearState,
	deleteSurveyLine,
	setDarkMode,
	setMapVisibility,
	setTerrainVisibility,
	setTheaterMode,
	updateAisData,
	updateAsset,
	updateAssets,
	updateBroadcasts,
	updateFleet,
	updateFleets,
	updateOrganization,
	updateOrganizations,
	updatePolyline,
	updatePolylines,
	updateProject,
	updateProjects,
	updateRightSidebar,
	updateSurveyLine,
	updateSurveyLines,
	updateTerrains,
	updateUser,
	updateUserAssets,
	updateUsers,
	updateVideo,
	updateVideos,
	updateWaypoint,
	updateWaypoints,
} from "./state-actions";
import { autoinject } from "aurelia-framework";
import { Store } from "aurelia-store";
import { State } from "./state";
import {
	IAsset,
	IOrganization,
	IProject,
	IVideo,
	IWaypoint,
} from "models/interfaces";

@autoinject()
export class StateManager {
	_store: Store<State>;
	_dataService: DataService;

	constructor(store: Store<State>, dataService: DataService) {
		this._store = store;
		this._dataService = dataService;
	}

	/****************************/
	/*** ORGANIZATION SECTION ***/
	/****************************/
	async getOrganizationsAsync() {
		const organizations =
			await this._dataService.getOrganizationsFromServerAsync();
		this._store.dispatch(updateOrganizations, organizations);
	}

	async getOrganizationAsync() {
		const organization =
			await this._dataService.getOrganizationFromServerAsync("me");
		this._store.dispatch(updateOrganization, organization);
	}

	async postOrganizationAsync(newOrganization: IOrganization) {
		const organization = await this._dataService.postOrganizationAsync(
			newOrganization
		);
		this._store.dispatch(updateOrganization, organization);
		return organization;
	}

	async updateOrganizationAsync(clientOrganization: IOrganization) {
		const organization = await this._dataService.updateOrganizationAsync(
			clientOrganization
		);
		this._store.dispatch(updateOrganization, organization);
	}
	async updateWithoutSaveOrganizationAsync(
		clientOrganization: IOrganization
	) {
		this._store.dispatch(updateOrganization, clientOrganization);
	}

	async deleteOrganizationAsync(organization: IOrganization) {
		await this._dataService.deleteOrganizationFromServerAsync(organization);
	}

	async selectOrganization(organization: IOrganization) {
		this._store.dispatch(updateOrganization, organization);
	}
	/****************************/

	/***********************/
	/*** PROJECT SECTION ***/
	/***********************/
	async getProjectsAsync(params) {
		const projects = await this._dataService.getProjectsFromServerAsync(
			params
		);
		this._store.dispatch(updateProjects, projects);
	}

	async getProjectAsync(projectId: string) {
		const Project = await this._dataService.getProjectFromServerAsync(
			projectId
		);
		this._store.dispatch(updateProject, Project);
	}

	async postProjectAsync(project: IProject, params) {
		const newProject = await this._dataService.postProjectAsync(
			project,
			params
		);
		this._store.dispatch(updateProject, newProject);
		return newProject;
	}

	async updateProjectAsync(clientProject: IProject) {
		const project = await this._dataService.updateProjectAsync(
			clientProject
		);
		this._store.dispatch(updateProject, project);
	}
	async selectProjectAsync(project: IProject) {
		this._store.dispatch(updateProject, project);
	}

	async deleteProjectAsync(project: IProject, params?: any) {
		await this._dataService.deleteProjectFromServerAsync(project, params);
	}
	/***********************/

	/*********************/
	/*** VIDEO SECTION ***/
	/*********************/
	async getVideosAsync(parameters) {
		const videos = await this._dataService.getVideosFromServerAsync(
			parameters
		);
		this._store.dispatch(updateVideos, videos);
	}

	async getVideoAsync(dataId, params) {
		const video = await this._dataService.getVideoFromServerAsync(
			dataId,
			params
		);
		this._store.dispatch(updateVideo, video);
	}

	async postVideoAsync(video: IVideo) {
		const newVideo = await this._dataService.postVideoAsync(video);
		this._store.dispatch(updateVideo, newVideo);
		return newVideo;
	}

	async updateVideoAsync(clientVideo: IVideo) {
		const video = await this._dataService.updateVideoAsync(clientVideo);
		this._store.dispatch(updateVideo, video);
	}

	async deleteVideoAsync(video: IVideo) {
		await this._dataService.deleteVideoFromServerAsync(video);
	}
	/*********************/

	async getBroadcastsAsync(parameters) {
		const broadcasts = await this._dataService.getBroadcastsFromServerAsync(
			parameters
		);
		this._store.dispatch(updateBroadcasts, broadcasts);
	}
	async deleteBroadcastAsync(broadcast) {
		await this._dataService.deleteBroadcastFromServerAsync(broadcast);
	}

	/*********************/
	/*** ASSET SECTION ***/
	/*********************/
	// async getAssetsAsync() {
	// 	const assets = await this._dataService.getAssetsFromServerAsync();
	// 	this._store.dispatch(updateAssets, assets);
	// }

	// async getAssetAsync(assetId: string) {
	// 	const asset = await this._dataService.getAssetFromServerAsync(assetId);
	// 	this._store.dispatch(updateAsset, asset);
	// }

	async selectAssetAsync(asset: IAsset) {
		this._store.dispatch(updateAsset, asset);
	}

	// async postAssetAsync(asset: IAsset) {
	// 	const newAsset = await this._dataService.postAssetAsync(asset);
	// 	this._store.dispatch(updateAsset, newAsset);
	// 	return newAsset;
	// }

	// async updateAssetsAsync(clientAssets: IAsset[], projectId: string) {
	// 	const assets = await this._dataService.updateAssetsAsync(
	// 		clientAssets,
	// 		projectId
	// 	);
	// 	this._store.dispatch(updateAssets, assets);
	// }

	// async updateAssetAsync(clientAsset: IAsset) {
	// 	const asset = await this._dataService.updateAssetAsync(clientAsset);
	// 	this._store.dispatch(updateAsset, asset);
	// }

	// async deleteAssetAsync(asset: IAsset) {
	// 	await this._dataService.deleteAssetFromServerAsync(asset);
	// }

	async setAssetVisibility(asset: IAsset) {
		this._store.dispatch(updateAsset, asset);
	}

	/*********************/

	/************************/
	/*** WAYPOINT SECTION ***/
	/************************/
	async getWaypointsAsync() {
		const waypoints = await this._dataService.getWaypointsFromServerAsync(
			{}
		);
		this._store.dispatch(updateWaypoints, waypoints);
	}

	async getWaypointAsync(waypointId: string) {
		const waypoint = await this._dataService.getWaypointFromServerAsync(
			waypointId
		);
		this._store.dispatch(updateWaypoint, waypoint);
	}

	async postWaypointAsync(waypoint: IWaypoint) {
		const newWaypoint = await this._dataService.postWaypointAsync(waypoint);
		this._store.dispatch(updateWaypoint, newWaypoint);
		return newWaypoint;
	}

	async updateWaypointAsync(clientWaypoint: IWaypoint) {
		const waypoint = await this._dataService.updateWaypointAsync(
			clientWaypoint
		);
		this._store.dispatch(updateWaypoint, waypoint);
	}

	async deleteWaypointAsync(waypoint: IWaypoint) {
		await this._dataService.deleteWaypointFromServerAsync(waypoint);
	}
	/************************/

	/***************************/
	/*** SURVEY LINE SECTION ***/
	/***************************/
	async getSurveyLinesAsync() {
		const surveyLines =
			await this._dataService.getSurveyLinesFromServerAsync();
		this._store.dispatch(updateSurveyLines, surveyLines);
	}

	async getSurveyLineAsync(surveyLineId: string) {
		const surveyLine = await this._dataService.getSurveyLineFromServerAsync(
			surveyLineId
		);
		this._store.dispatch(updateSurveyLine, surveyLine);
	}

	async postSurveyLineAsync(surveyLine: ISurveyLine) {
		const newSurveyLine = await this._dataService.postSurveyLineAsync(
			surveyLine
		);
		this._store.dispatch(addSurveyLine, newSurveyLine);
		return newSurveyLine;
	}

	async updateSurveyLineAsync(surveyLine: ISurveyLine) {
		const updatedSurveyLine = await this._dataService.updateSurveyLineAsync(
			surveyLine
		);
		return this._store.dispatch(updateSurveyLine, updatedSurveyLine);
	}

	async deleteSurveyLineAsync(surveyLine: ISurveyLine) {
		await this._dataService.deleteSurveyLineFromServerAsync(surveyLine);
		return this._store.dispatch(deleteSurveyLine, surveyLine);
	}
	/***************************/

	/************************/
	/**** NEW ASSETS SECTION ****/
	/************************/
	async getAssetsAsync(parameters?) {
		const assets = await this._dataService.getAssetsFromServerAsync(
			parameters
		);
		this._store.dispatch(updateAssets, assets);
	}

	async getAssetAsync(assetId: string) {
		const asset = await this._dataService.getAssetFromServerAsync(assetId);
		this._store.dispatch(updateAsset, asset);
	}

	async postAssetAsync(asset: IAsset, params?) {
		const newAsset = await this._dataService.postAssetAsync(asset, params);
		this._store.dispatch(updateAsset, newAsset);
		return newAsset;
	}

	async updateAssetAsync(clientAsset: IAsset, params?) {
		const asset = await this._dataService.updateAssetAsync(
			clientAsset,
			params
		);
		this._store.dispatch(updateAsset, asset);
	}

	async deleteAssetAsync(asset: IAsset, params?) {
		await this._dataService.deleteAssetFromServerAsync(asset, params);
	}
	/************************/

	/************************/
	/**** FLEETS SECTION ****/
	/************************/
	async getFleetsAsync(params?) {
		const fleets = await this._dataService.getFleetsFromServerAsync(params);
		this._store.dispatch(updateFleets, fleets);
	}

	async getFleetAsync(fleetId: string) {
		const fleet = await this._dataService.getFleetFromServerAsync(fleetId);
		this._store.dispatch(updateFleet, fleet);
	}

	async postFleetAsync(fleet: IFleet, params?) {
		const newFleet = await this._dataService.postFleetAsync(fleet, params);
		this._store.dispatch(updateFleet, newFleet);
		return newFleet;
	}

	async updateFleetAsync(clientFleet: IFleet, params?) {
		const fleet = await this._dataService.updateFleetAsync(
			clientFleet,
			params
		);
		this._store.dispatch(updateFleet, fleet);
	}

	async deleteFleetAsync(fleet: IFleet, params?) {
		await this._dataService.deleteFleetFromServerAsync(fleet, params);
	}
	/************************/

	/***************************/
	/*** TERRAINS SECTION ***/
	/***************************/

	async getTerrainsAsync() {
		const terrains = await this._dataService.getTerrainsFromServerAsync();
		this._store.dispatch(updateTerrains, terrains);
	}

	/***************************/

	/***************************/
	/*** TERRAINS SECTION ***/
	/***************************/

	async getAisData() {
		const aisData = await this._dataService.getAisDataFromServerAsync();
		this._store.dispatch(updateAisData, aisData);
	}

	/***************************/

	/*************************/
	/*** POLYLINES SECTION ***/
	/*************************/
	async getPolylinesAsync() {
		const polylines = await this._dataService.getPolylinesFromServerAsync();
		this._store.dispatch(updatePolylines, polylines);
	}
	async selectPolylineAsync(polyline: IPolyline) {
		this._store.dispatch(updatePolyline, polyline);
	}
	async setPolylineVisibility(polyline: IPolyline) {
		this._store.dispatch(updatePolyline, polyline);
	}

	/*************************/

	/*************************/
	/****** USER ASSETS ******/
	/*************************/

	async getUserAssetsAsync(params) {
		const userAssets = await this._dataService.getUserAssetsAsync(params);
		this._store.dispatch(updateUserAssets, userAssets);
	}

	/*************************/

	/*********************/
	/*** RIGHT SIDEBAR ***/
	/*********************/

	async setRightSidebar(value) {
		const newValue = value;
		this._store.dispatch(updateRightSidebar, newValue);
	}
	/*********************/

	async setTerrainVisibility(value: boolean) {
		this._store.dispatch(setTerrainVisibility, value);
	}
	async setMapVisibility(value: boolean) {
		this._store.dispatch(setMapVisibility, value);
	}

	async getUsersAsync(params) {
		const users = await this._dataService.getUsersFromServerAsync(params);
		this._store.dispatch(updateUsers, users);
		return users;
	}

	async updateUserAsync(clientUser: IUser, params) {
		const user = await this._dataService.updateUserAsync(
			clientUser,
			params
		);
		this._store.dispatch(updateUser, user);
	}
	async addUserAsync(clientUser: IUser, params) {
		const user = await this._dataService.addUserAsync(clientUser, params);
		this._store.dispatch(updateUser, user);
	}
	async deleteUserAsync(user: IUser, params) {
		await this._dataService.deleteUserFromServerAsync(user, params);
	}

	async getUserAsync() {
		const user = await this._dataService.getUserFromServerAsync();
		this._store.dispatch(updateUser, user);
		return user;
	}

	async updateUserVideoStateAsync(userVideoState) {
		await this._dataService.updateUserVideoStateAsync(userVideoState);
	}

	async setDarkMode(boolValue) {
		this._store.dispatch(setDarkMode, boolValue);
	}
	async setTheaterMode(boolValue) {
		this._store.dispatch(setTheaterMode, boolValue);
	}

	async clearState() {
		this._store.dispatch(clearState);
	}
}
